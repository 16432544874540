:root {
  --color6: rgb(238, 238, 238);
  --color7: rgb(238, 214, 172);
  --color8: rgb(172, 238, 215);
  --color9: #03183a;
  --color10: #2e9a7d;/*#39ac8e;*/
  --color11: rgba(254, 254, 255, 0.625);
  /*rgba(2, 19, 47, 0.332);*/
  --color12: rgba(254, 254, 255, 0.625);
  /*rgba(2, 19, 47, 0.332);*/
  --color13: hsla(155, 55%, 91%, 0.948);
  --color14: #d1d1d164;
  --color15: #25ddac;
  --color16: #39ac8d20;
  --color17: #16423668;
  --color20: rgb(98, 255, 0);

  --warning: rgb(248, 171, 103);
  --danger: rgb(210, 88, 88);
}

.css-176wh8e-MuiCircularProgress-circle {
  color: #39ac8e;
}

* {
  /*font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;*/
  --ion-font-family: 'Montserrat', sans-serif;
  font-family: 'Montserrat', sans-serif;
  color: var(--color9);
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 0;
  width: 100%;
  text-decoration: none;
}

.Cursor {
  cursor: pointer;
}


#BodyFittizio {
  min-height: 100vh;
}


select {
  font-size: 14px;
  border: 0.5px solid rgb(10, 33, 71);
  border-radius: 5px;
  padding: 3px 5px;
  margin-bottom: 30px;
  margin-top: 10px;
  height: fit-content;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.343);
  width: fit-content;
  min-width: 150px;
  max-width: 200px;
}

input[type='date'],
input[type='file'] {
  min-width: fit-content;
  max-width: fit-content;
  min-height: 1rem;
}

input[type='checkbox'],
input[type='radio'] {
  min-width: 17px;
  max-width: fit-content;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.343);

}

label {
  padding-right: 5px;
  font-size: 14px;
  height: fit-content;
}

p {
  line-height: 200%;
}

li {
  line-height: 150%;
}

button {
  border-radius: 10px;
  background-color: var(--color10);
  color: #f0fbf8;
  padding: 5px 10px;
  font-weight: bold;
  height: fit-content;
  width: fit-content;

}

button:hover {
  background-color: hsl(164, 53%, 51%);
}



.Loading {
  text-align: center;
}

.master-title h2 {
  font-size: 40px;
  margin: 15px;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
  color: rgb(10, 33, 71)
}

#h1span {
  font-size: 40px;
  margin: 0;
  padding: 0;
  color: hsl(162, 51%, 87%);
}


h2,
h3 {
  text-align: center;
  margin-bottom: 40px;
}

h4 {
  margin-top: 50px;
}

.master-title {
  margin: 8px 0;

}

.master-title p {
  margin: 0;
  padding: 0;
  font-size: 1.4vw;
}



/* Sovra Interstazione */
#SovraIntestazione {
  background-color: hsl(164, 51%, 31%);
  display: flex;
  display: -webkit-flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  color: #e4f9f4;
  padding: 0.5% 3%;
  margin: 0;
}

#SovraIntestazione label,
#SovraIntestazione div {
  font-size: 1vw;
  margin: 0;
  padding: 0;
}

img {
  width: auto;
  height: 1.5vw;
}

#whatsappdiv,
#ContattiCell,
#ContattiMail {
  display: flex;
  gap: 0.5vw;
  align-items: center;
}

/* Intestazione */
#Intestazione {
  background-color: var(--color10);
  gap: 1%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.master-title {
  text-align: center;
  font-size: 6vw;
}

.navbar {
  width: 60%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: right;
  align-items: center;
  padding-right: 50px;
  margin-left: 0;
}

.nav-item {
  text-decoration: none;
  color: #e4f9f4;

  font-weight: 550;
  padding: 5px 10px;
  margin: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;


}


.nav-item:hover {
  background-color: #5ec3a8bc;
  border-radius: 10px;
}

#InvioMADNav {
  background-color: #9de1ce92;
  color: rgb(10, 33, 71);
  border-radius: 10px;
  padding: 0.4rem 0.5rem;
}

#InvioMADNav:hover {
  background-color: #c4efe392;
}

#InvioMADNav2:hover,
#InvioMADNav3:hover,
#InvioMADNav4:hover,
#InvioMADNav5:hover {
  background-color: #4aae9292;
}

/* Invio MAD */
/* tipoMad */
#TipoMad {
  text-align: center;
  display: flex;
  flex-flow: column wrap;
  text-align: center;
  margin: 3% 0;
}

#MadDocente {
  box-shadow: 0;
  border-radius: 50000px;
}


#TipoMad h1 {
  font-weight: 600;
  color: rgb(10, 33, 71); 

}

#TipoMad div {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 3%;
}

#TipoMad button {
  background-color: var(--color10);
  /*border: 0.5px solid rgb(10, 33, 71);*/
  border-radius: 10px;
  font-weight: 500;
  color: #f0fbf8;

  padding: 5px 10px;
  width: 100px;
}

#TipoMad button:hover {
  background-color: hsla(164, 50%, 45%, 0.786);
}

#BottoniDocenteAta {
  margin-bottom: 5%;
}

#InviaLink,
.MADIT {
  text-decoration: none;
}

.button {
  padding: 0.75rem;
  border-radius: 1rem;
  box-shadow: 0 1px 5px rgba(128, 128, 128, 0.286);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Spuntecheckbox {
  margin: 1rem;

}

input.Spuntecheckbox,
input.radioPec {
  transform: scale(1.3);
}

.ItemDaSpuntare {
  padding: 1rem 0;
}

.ItemDaSpuntare label {
  line-height: 1.5;
}

/* Inserisci Dati e Riepilogo*/
.container-inserisci-dati {
  padding: 2rem 1rem;
  color: var(--color9);
  display: block;
}

#NomeCognomeSesso {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: center;
  gap: 5%;
}

#sesso {
  display: flex;
  flex-flow: row wrap;
  height: fit-content;
  text-align: center;
  gap: 10px;
}

#DataNascitaComuneNascita {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: center;
  gap: 5%;
}

#DataDiNascita {
  width: fit-content;
}

#container-ComuneNascita {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: flex-end;

}

#ComuneNascita {
  width: fit-content;
}

#container-StatoNascita {
  width: fit-content;
}

#container-StatoNascita select {
  width: 200px;
}


#Residenza {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: left;
  gap: 5%;
}

#divResidenza div select {
  width: fit-content;
  min-width: 200px;
}

#IndirizzoResidenza input {
  width: 45%;
  min-width: 200px;
}

#SessoCodiceFiscale {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: center;
  gap: 5%;
}

#MailContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: center;
  gap: 5%;
}

#PecContainer {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: center;
  gap: 5%;
}

#CellulareTelefono {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: center;
  gap: 5%;
}

#TitoloStudioLivelloIstruzione {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: center;
  gap: 5%;
}

/*
#LivelloIstruzione {
  width: 50%;
}
*/
#MassimoPunteggioTitolo {
  margin-top: 10px;
}

#PunteggioTitoloOttenuto {
  margin-bottom: 0;
  margin-right: 20px;
}

#PunteggioLode {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  align-items: center;
  gap: 5%;
}

#CertificazioniInglese {
  margin-bottom: 10px;
}

#CertificazioniInformatiche {
  display: flex;
  flex-flow: row wrap;
  gap: 15px;

}

#Container-SpunteCertificazioniInformatiche {
  display: flex;
  flex-flow: column wrap;
}

#Container-SpunteCertificazioniInformatiche .Spunte {
  gap: 8px;
}

#CertificazioniAggiuntive {
  margin-top: 30px;
  margin-bottom: 30px;
}

#CertificazioniAggiuntive legend {
  margin-bottom: 10px;
}

#CertificazioniAggiuntive input {
  margin-bottom: 5px;
}

#CertificazioniAggiuntive label {
  margin-left: 8px;
}


#Documento,
#Curriculum,
#MAD {
  margin-bottom: 20px;
}

#divDocumento,
#divCurriculum,
#divMAD {
  display: grid;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 1%;
}

#divDocumento input,
#divCurriculum input,
#divMAD input {
  margin: 15px 0;
}

.Caricato {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 5px;
}

.CaricatoNONRiuscito {
  font-weight: bold;
  color: rgb(202, 11, 11);
  padding: 0.5rem 1rem;
  margin: 0.8rem auto 1rem auto;
  border: 1px solid grey;
  border-radius: 0.5rem;
  font-size: 12px;
}

.uploadbutton::file-selector-button {
  background: white;
  border-radius: 0.5rem;
  font-size: 14px;
  border: 0px solid var(--color10);
  /*
  box-shadow: 0 0.5px 2px var(--color10);
  color: var(--color10);*/
  color: white;
  background:var(--color10) ;
  padding: 0.4rem 0.6rem;
  font-weight: 500;
  margin: 0.5rem;
}

.uploadbutton {
  font-size: 14px;
  color: var(--color9);
}

.Dichiarazioni {
  margin-top: 50px;
}

.Dichiarazioni {
  margin-top: 50px;
}

.Spunte {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1%;
  width: 100%;
}

.Spunte {
  text-align: justify;
  margin-bottom: 10px;
}

.Spunte input {
  margin: 0;
}

.Avanti {
  margin-top: 20px;
  display: flex;
  justify-content: right;
  margin-bottom: 200px;
}

.Avanti .Link {
  text-decoration: none;
  color: #f0fbf8;
}

#container-ProfiliProfessionaliInvio,
#container-ClassiConcorsoInvio,
#container-ProvinceRichiestePerLInvio {
  margin: 1rem;
  font-size: 14px;
}


#divOptions {
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: left;
  gap: 3%;
  padding: 10px;
}

.Options input {
  margin: 0;
  padding: 0;
}

.Options p {
  font-size: 14px;
  margin: 0;
  padding: 0;
  object-fit: fill;
}


#container-ProvinceRichiestePerLInvio {
  margin-bottom: 30px;

}

#LegendTextarea {
  margin-bottom: 10px;
  margin-top: 60px;
  font-size: 14px;
}

textarea {
  width: 80%;
  padding: 20px;
  font-size: 14px;
  border: 0.5px solid rgb(10, 33, 71);
  border-radius: 5px;
  font-size: 12px;
  margin: 0 auto;
  height: 300px;
}

#Spiegazione-ProvinceRichieste {
  font-size: 12px;
}

#h3TitoliServizio {
  padding-top: 40px;
}

#TitoliServizio-container button {
  margin-top: 10px;
  margin-right: 10px;
}

/* METODOLOGIA PEC */

#SceltaTipologiaInvio-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin-bottom: 20px;
}

#SceltaTipologiaInvio-container label {
  font-size: 16px;
  margin: 10px;
}

.RadioMetodologiaPec {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 7px;
}

.RadioMetodologiaPec input {
  margin: 0;
}

.AvvertenzaSceltaPec {
  font-size: 12px;
}

#SceltaTipologiaInvio {
  margin: 0;
  margin-top: 60px;
  margin-bottom: 30px;
}

#div-AvvertenzaSceltaPec {
  text-align: justify;
  width: 90%;
  margin: 2rem auto;
}

#PecPropria-container {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 16px;
}

#IndirizzoPecEPasswordPec-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: left;
}

#IndirizzoPec-container {
  margin-right: 40px;
}

#FornitorePec-e-Verifica-Atteso-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
}

#FornitorePec-e-Verifica-Atteso-container button {
  margin-left: 15px;
}

#Attendere-Verifica-container {
  font-size: 16px;
  text-decoration: underline;
}

#Attendere-Verifica-container p {
  border: 1px solid grey;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 60px;
}

#BoldConcesso {
  color: hsl(164, 65%, 27%);
}

#BoldNegato {
  color: rgb(202, 11, 11);
}


#Conferma {
  margin-top: 10px;
}


/* RIEPILOGO */
#ContainerRiepilogo {
  border: 1px solid hsla(164, 50%, 45%, 0.786);
  border-radius: 10px;
  padding-bottom: 5%;
  background-color: rgba(184, 187, 184, 0.16);
  font-size: 2vw;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.343);
}

#ContainerRiepilogo section {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 5%;
  align-items: center;
  border: 0.5px solid hsla(164, 50%, 45%, 0.786);
  background-color: hsla(164, 51%, 48%, 0.786);
  ;
}

#ContainerRiepilogo h2 {
  margin: 0;
  padding: 8%;
}


#ContainerRiepilogo div {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: left;
  margin: 2% 5%;
}

.AttendereAccettaTerminieCondizioni {
  text-align: right;
  margin-right: 60px;
}


/* Home */
#VideoHome,
.homeIMG {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: cover;
  position: static;
}

#container-homeIMG {
  min-height: 55vw;
  max-width: 150vw;
}


#BollinoHome div {
  font-size: 1.5vw;
  text-align: center;
  color: white;


}



#bollinoDivInerno {
  margin-top: 30px;
  text-align: center;

}

#novecinque {
  font-size: 3vw;
  text-align: center;
  color: var(--color10);
}

@media screen and (max-width: 700px) {
  #BollinoHome div {
    font-size: 2.5vw;
  }

  #novecinque {
    font-size: 3.5vw;
  }
}

.home-container #TestoSuVideoSx {
  position: absolute;
  z-index: 3;
  margin-top: 3%;
  font-size: 2vw;
  font-weight: 600;
  color: #f0fbf8;
}

.home-container #TestoSuVideoSx p {
  font-weight: 600;
  color: #8edac6e3;
}


#TestoSuVideoSx {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;


}

#TestoSuVideoSx h1 {
  text-align: left;
}

#ScrittaBiancaHome li {
  margin: 15% 0;
}

.ListaHome li {
  margin-bottom: 4%;
}

.home-container a {
  text-decoration: none;
  color: #f0fbf8a2;
}




#Container-CosèlaMad {
  padding-top: 3%;
  text-align: justify;
  color: rgb(10, 33, 71);
}

#Container-CosèlaMad h2 {
  text-decoration: underline hsl(164, 51%, 46%);
}

.CosèlaMad {
  margin-bottom: 0;
  padding-bottom: 0;
  font-weight: 700;
}

.VideoHomeYoutube {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  margin-bottom: 40px;
}

#VideoHomeYoutube {
  width: min(90%, 600px);
  height: 100%;
  margin-top: 2vw;
  border: 0;

}

#idVideoHomeYoutube {
  padding-top: 40px;
}

#Container-ComeFunzionaMADIT {
  background-color: hsla(180, 1%, 54%, 0.135);
  margin: 0;
  padding-top: 3%;
  text-align: justify;

}

.MADIT,
#InviaLink {
  color: var(--color10);
}

.liHome {
  padding: 10x;
}

.ListaHome {
  padding-right: 10px;
}


#buttonInformazioni {
  padding: 20px;
  border-radius: 15px;
}

#divButtonInformazioni {
  text-align: center;
  margin: 40px auto 20px auto;

}

#divButtonInterpelli {
  text-align: center;
  margin: 40px auto 20px auto;

}

.ContainerInfrmazioni h2 {
  font-weight: 700;
}


@media screen and (max-width: 700px) {

  #Container-ComeFunzionaMADIT,

  #Container-ComeFunzionaMADIT,
  .PrezziContainer {
    padding: 5% 6vw;
  }
}


@media screen and (min-width: 700px) {

  #Container-ComeFunzionaMADIT,

  #Container-ComeFunzionaMADIT,
  .PrezziContainer {
    padding: 5% 7vw;
  }
}

@media screen and (max-width: 700px) {

  #CardInformazioniHome,
  #CardInterpelliHome {
    padding: 20px;

  }
}

@media screen and (min-width: 700px) {
  #RowsTextnHome {
    display: flex;
    flex-flow: column nowrap;
    gap: 0px;
  }

  #RowsTextnHome>div {
    flex: 1;
  }

  #ColumnsTextnHome {
    display: flex;
    flex-flow: row nowrap;
    gap: 40px;
  }

  #ColumnsTextnHome>div {
    flex: 1;
  }

  #CardInformazioniHome,
  #CardInterpelliHome {
    padding: 40px;

  }
}


.TitleInvioMad {
  padding: 40px;
  font-size: 20px;
}



/* Prezzi */
.PrezziContainer {
  color: rgb(10, 33, 71);
}

.Card {
  border-radius: 10px;
  background-color: hsl(0, 0%, 100%);
  margin-top: 5%;

  font-size: 1.5vw;
  height: fit-content;

}

.Card h3 {
  padding: 0 2vw;
  margin-bottom: 1.5vw;
}

#Card-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
}

.costo {
  font-weight: 400;
  font-size: 3.5vw;
  text-align: center;
  margin: 12px 0;
}

.container-Features {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
}

.container-Features1 {
  border: 0.5px solid rgba(255, 119, 0, 0.966);
  background-color: white;
}

.container-Features1-5 {
  border: 0.1px solid transparent;
  background-color: white;
}

.container-Features2 {
  border: 0.1px solid transparent;
  background-color: white;
}

.container-Features3 {
  border: 0.1px solid rgba(77, 75, 75, 0);
  background-color: hsl(0, 22%, 96%);
}

.container-Features6{
  border: 0.1px solid transparent;
  background-color: white;
}

.container-Features7{
  border: 0.1px solid transparent;
  background-color: white;
}

.features {
  display: flex;
  flex-flow: column wrap;
  margin: 2vw;
}

.features label {
  font-size: 1.2vw;
  margin-bottom: 10%;
}

#Card-container>div.Card>p {
  font-size: 2.4vw;
}

.Card1 {
  border: 0.5px solid rgba(255, 119, 0, 0.654);
  background-color: rgb(244, 212, 188);
}

.Card1-5 {
  border: 0.5px solid var(--color10);
  background-color: hsl(162, 60%, 61%);
}

.Card2 {
  border: 0.5px solid hsl(210, 65%, 45%);
  background-color: hsl(210, 68%, 68%);
}

.Card3 {
  color: rgba(27, 25, 25, 0.919);
  border: 0.1px solid rgba(74, 73, 73, 0.722);
  background-color: hsla(0, 51%, 36%, 0.535);
}

.Card6{
  color: rgba(27, 25, 25, 0.919);
  border: 0.1px solid hsla(24, 84%, 55%, 0.96);
  background-color: hsla(24, 89%, 66%, 0.96);
}

.Card7 {
  border: 0.5px solid hsla(39, 80%, 60%, 0.96);
  background-color: hsla(39, 84%, 56%, 0.96);
}



.Card1 .costo,
.label1 {
  color: rgba(255, 119, 0, 0.767);
  font-weight: 600;
}

.Card1-5 .costo,
.label1-5 {
  color: hsl(164, 85%, 29%);
  font-weight: 600;
}

.Card2 .costo,
.label2 {
  color: hsl(210, 80%, 35%);
  font-weight: 600;
}

.Card6 .costo{
  color: #da4100;
  font-weight: 600;
}

.Card7 .costo {
  color: hsla(21, 95%, 47%, 0.96);
  font-weight: 600;
}

.label3 {
  color: rgba(145, 13, 13, 0.854);
}

#AvvertenzaPrezzo {
  font-size: 1.2vw;
  text-align: center;
  margin: 3% 0;
  margin-bottom: 150px;
}

.container-Features3 label {
  font-size: 1.1vw;
}

.Incipit-Prezzi {
  text-align: justify;
  font-size: 1.5vw;
  margin-top: 3%;
}

#Prezzi h2 {
  text-align: center;
}



/* Footer */
footer {
  position: relative;
  bottom: 0;
  width: 100%;

}

#div-Footer {
  display: flex;
  gap: 5%;
  align-items: center;
  justify-content: left;
  background-color: var(--color10);
  padding: 2% 0%;
  text-align: center;
  padding-left: 3%;
  height: 50px;
}

/*
#LogoAws {
  width: 16vw;
  height: 5vw;
  border-radius: 5px;
  margin: 0;
  margin-top: 5px;
  margin-right: 50px;
  padding: 0;
}
*/

#div-Footer .Link {
  font-size: max(1.5vw, 12px);
  text-decoration: none;
  color: var(--color9);
  margin: 0;
}





/* Contatti */
.Contatti-container {
  background-color: hsl(105, 15%, 95%);
  padding: 10%;
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  gap: 5vw;
}

.Contatti-container div {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 5%;
}

.Contatti-container h1 {
  font-size: 3vw;
}

.Contatti-container label {
  font-size: 2vw;
}

.Contatti-container p {
  font-size: 1.5vw;
}

@media screen and (max-width: 500px) {
  .Contatti-container h1 {
    font-size: max(6vw, 12px);
  }

  .Contatti-container label {
    font-size: max(4vw, 12px);
  }

  .Contatti-container p {
    font-size: max(2.5vw, 12px);
  }
}

#MailIcon,
#TelegramIcon,
#WhatsapIcon {
  width: 5vw;
  height: 5vw;
}

.pContatti {
  text-align: justify;
}

/* Pagamento */
#containerPagamento {
  text-align: center;
  width: 70%;
  margin: 0 auto;
  padding-top: 30px;
}

#Pagamento-Contaire2 {
  text-align: center;
  padding-top: 0px;
  padding-bottom: 30px;
  font-size: 20px;
}

#Pagamento-Contaire2 h4 {
  margin: 0;
}

#PrezzoDovuto {
  border: 1px solid hsl(164, 49%, 48%);
  border-radius: 2rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  column-gap: 3rem;
  row-gap: 1rem;
  width: 40%;
  margin: 30px auto 20px auto;
  padding: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.343);
}

.labelImport {
  font-size: 25px;

}

#Container-PrezzoDovuto {
  text-align: center;
  padding-top: 20px;
}

.LabelPrezzoDovuto {
  font-size: 14px;

}

#inpitInserimentoCodiceSconto {
  width: 60%;
}

#OppureCodiceSconto {
  padding-top: 60px;
}

/* Avvenuto Invio */
#Container-AvvenutoInvio-1 {
  min-height: 200vw;
}

#Container-AvvenutoInvio-1 h4 {
  text-align: center;
}

#Container-AvvenutoInvio {
  display: flex;
  flex-flow: column wrap;
  padding-top: 10px;
}

#Container-AvvenutoInvio h3 {
  font-size: 30px;
  color: hsl(121, 69%, 44%);
  margin-top: 0;
  margin-bottom: 20px;
}

#TitoloCard,
#ListadellaCard {
  border: 1px solid black;
  width: 60%;
  margin: 0 auto;
}

#TitoloCard {
  text-align: center;
  font-size: 14px;
  background-color: hsl(121, 66%, 42%);
  color: white;
  border-bottom: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 20px;
}

#TitoloCard p {
  margin: 0;
  font-weight: 700;
}

#ListadellaCard {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

#ListadellaCard li {
  font-size: 12px;
  padding: 5px;
  margin: 5px;
  text-align: justify;
  line-height: 15px;

}

#ListadellaCard ul {
  padding-right: 10px;
  padding-left: 20px;
}

#SpuntaIcon {
  width: 150px;
  height: 30%;
  margin: 0 auto;
}

#Container-SpiegazioneRicevteArgo p {
  padding: 40px;
  padding-top: 0;
}

#Container-SpiegazioneRicevteArgo h4 {
  padding-top: 40px;
}

.ImgArgo {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.IMG {
  width: 50%;
  height: auto;
  margin: 0 auto;
  margin-bottom: 50px;
}


/* Termini e Condizioni */

.container-TerminieCondizioni {
  padding: 2rem;
  text-align: justify;
  font-size: 14px;
}


.container-TerminieCondizioni h1 {
  text-align: center;
  font-size: 18px;
  font-weight: 900;
}


.container-TerminieCondizioni h4 {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
}

.container-TerminieCondizioni li {
  margin-top: 1rem;
}

.CondizioneRedatteIl {
  margin-bottom: 150px;
}

.divTerminieCondizioni {
  padding-left: 60%;
}

#LinkTerminieCondizioni {
  text-align: center;
}

#fb-icon2,
#yt-icon2,
#Whatsapp1,
.mail {
  width: 14px;
  height: 100%;
  margin: 2px;
}






/* Intestazione */
@media screen and (max-width: 500px) {
  .nav-item {
    font-size: max(2.5vw, 10px);
    min-height: 20px;
  }

  .navbar {
    justify-content: center;
    padding-right: 10px;
    width: 100%;
  }

  #InvioMADNav {
    border-radius: 0.75rem;
  }

  #InvioMADNav2,
  #InvioMADNav3,
  #InvioMADNav4:hover,
  #InvioMADNav5:hover {
    border-radius: 5px;
    padding: 5px;
  }

  .master-title h2 {
    font-size: 4.8vw;
  }

  #h1span {
    font-size: 4.3vw;
  }
}

@media screen and (min-width: 500px) {
  .nav-item {
    font-size: 2.5vw;
    min-height: 20px;
  }

  #InvioMADNav {
    border-radius: 0.75rem;
  }

  #InvioMADNav2,
  #InvioMADNav3,
  #InvioMADNav4:hover,
  #InvioMADNav5:hover {
    border-radius: 5px;
    padding: 10px;
  }

  .master-title h2 {
    font-size: 6vw;
  }

  #h1span {
    font-size: 5.5vw;
  }
}

@media screen and (min-width: 700px) {
  .nav-item {
    font-size: 1.8vw;
    min-height: 0px;
  }

  #InvioMADNav {
    border-radius: 0.75rem;
  }

  #InvioMADNav2,
  #InvioMADNav3,
  #InvioMADNav4:hover,
  #InvioMADNav5:hover {
    border-radius: 5px;
    padding: 10px;
  }

  .master-title h2 {
    font-size: 5.5vw;
  }

  #h1span {
    font-size: 5vw;
  }
}

@media screen and (min-width: 900px) {
  .nav-item {
    font-size: 1.2vw;
  }

  #InvioMADNav {
    border-radius: 0.75rem;
  }

  #InvioMADNav2,
  #InvioMADNav3,
  #InvioMADNav4:hover,
  #InvioMADNav5:hover {
    border-radius: 5px;
    padding: 10px;
  }

  .master-title h2 {
    font-size: 4vw;
  }

  #h1span {
    font-size: 3.5vw;
  }
}

/* Modello MAD */
/*
#divModelloMad {
  padding: 50px;
  text-align: justify;
}

#PrivacyModelloMad {
  font-size: 12px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.Firma {
  text-align: right;
  margin-right: 40px;
}

.ConOsservanza {
  margin-top: 50px;
}

.FirmaEffettiva {
  font-style: italic;
  margin-bottom: 400px;
}

#DescrizioneModelloMad {
  font-style: italic;
  margin-bottom: 50px;
}
*/

.LinkModelloMAD {
  text-decoration: underline;
  color: #2f1eafe3;
}

.DivQuotaMail {
  color: red;
  font-size: 12px;
  text-align: justify;
}


/* Articoli */
.h2Articolo {
  margin-top: 60px;
  font-size: 25px;
  text-align: left;
}

.h3Articolo {
  color: var(--color10);
  font-size: 20px;
}

.TitoloArticolo {
  font-size: 30px;
  text-align: left;
  margin-bottom: 40px;
  line-height: 1.5;
}

.container-TerminieCondizioni p,
.container-TerminieCondizioni li {
  font-size: 14px;
}

.ultimolinkInformazioni {
  padding: 10px;
  font-weight: 900;
  font-size: 25px;
  margin-top: 60px;
  text-align: justify;
}

.ultimolinkInformazioni a {
  max-width: fit-content;
}

.ultimaImg {
  margin-top: 80px;
  margin-bottom: 40px;
}

#linkInformazioni {
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}

#IndiceInformazioni {
  margin-top: 50px;
}

#ListaInformazioni {
  margin-bottom: 50px;
}

@media screen and (max-width: 700px) {
  .TitoloArticolo {
    font-size: 5.5vw;
  }

  .h2Articolo {
    font-size: 5vw;
  }

  .h3Articolo {
    font-size: 4.5vw;
  }


  #linkInformazioni {
    font-size: max(3vw, 12px);
  }

  .ultimolinkInformazioni {
    font-size: 4vw;
  }
}


/* Home */




/* Prezzi */
@media screen and (max-width: 500px) {

  .features label,
  .PerProvincia {
    font-size: max(2.3vw, 12px);
  }

  .container-Features3 label {
    font-size: max(2.3vw, 12px);
  }

  #AvvertenzaPrezzo {
    font-size: max(2.1vw, 12px);
  }

  .Card {
    width: 60%;
    font-size: max(3vw, 12px);
  }

  .Incipit-Prezzi {
    font-size: max(3.5vw, 12px);
  }

  #Prezzi h2 {
    font-size: 4.5vw;
    margin-bottom: max(6vw, 12px);
  }

  #Card-container>div.Card>p {
    font-size: max(4.4vw, 12px);
  }
}

@media screen and (min-width: 500px) {

  .features label,
  .PerProvincia {
    font-size: 1.8vw;
  }

  .container-Features3 label {
    font-size: 1.6vw;
  }

  #AvvertenzaPrezzo {
    font-size: 1.5vw;
  }

  .Card {

    font-size: 2vw;
  }

  .Incipit-Prezzi {
    font-size: 2.1vw;
  }

  #Prezzi h2 {
    font-size: 3.5vw;
  }
}

@media screen and (min-width: 700px) {

  .features label,
  .PerProvincia {
    font-size: 1.5vw;
  }

  .container-Features3 label {
    font-size: 1.3vw;
  }

  #AvvertenzaPrezzo {
    font-size: 1.2vw;
  }

  .Card {

    font-size: 1.8vw;
  }

  .Incipit-Prezzi {
    font-size: 1.7vw;
  }

  #Prezzi h2 {
    font-size: 2.5vw;
  }
}

@media screen and (min-width: 900px) {

  .features label,
  .PerProvincia {
    font-size: 1vw;
  }

  .container-Features3 label {
    font-size: 0.9vw;
  }

  #AvvertenzaPrezzo {
    font-size: 0.8vw;
  }

  .Card {

    font-size: 1.2vw;
  }

  .Incipit-Prezzi {
    font-size: 1.3vw;
  }

  #Prezzi h2 {
    font-size: 2vw;
  }
}

/* TipoMad */
@media screen and (max-width: 500px) {

  #Container-ComeFunzionaMADIT,
  #CardInformazioniHome,
  #CardInterpelliHome,
  .liHome {
    font-size: max(3vw, 12px);
  }

  #Container-ComeFunzionaMADIT h2,
  #CardInformazioniHome h2,
  #CardInterpelliHome h2 {
    margin: 5% 0 3% 0;
    font-size: max(4vw, 12px);
  }

  #TipoMad button {
    font-size: max(3vw, 12px);
    width: 20vw;
  }

  #TipoMad h1 {
    font-size: max(3vw, 12px);
  }
}

@media screen and (min-width: 500px) {

  #Container-ComeFunzionaMADIT,
  #CardInformazioniHome,
  #CardInterpelliHome,
  .liHome {
    font-size: 2vw;
  }

  #Container-ComeFunzionaMADIT h2,
  #CardInformazioniHome h2,
  #CardInterpelliHome h2 {
    margin: 5% 0 3% 0;
    font-size: 3.5vw;
  }

  #TipoMad button {
    font-size: 2.5vw;
    width: 15vw;
  }

  #TipoMad h1 {
    font-size: 3vw;
  }
}

@media screen and (min-width: 700px) {

  #Container-ComeFunzionaMADIT,
  #CardInformazioniHome,
  #CardInterpelliHome,
  .liHome {
    font-size: 1.5vw;
  }

  #Container-ComeFunzionaMADIT h2,
  #CardInformazioniHome h2,
  #CardInterpelliHome h2 {
    margin: 5% 0 3% 0;
    font-size: 3vw;
  }

  #TipoMad button {
    font-size: 2vw;
    width: 12vw;
  }

  #TipoMad h1 {
    font-size: 2.5vw;
  }
}

@media screen and (min-width: 900px) {

  #Container-ComeFunzionaMADIT,
  #CardInformazioniHome,
  #CardInterpelliHome,
  .liHome {
    font-size: 1.2vw;
  }

  #Container-ComeFunzionaMADIT h2,
  #CardInformazioniHome h2,
  #CardInterpelliHome h2 {
    margin: 5% 0 3% 0;
    font-size: 2vw;
  }

  #TipoMad button {
    font-size: 1.5vw;
    width: 8vw;
  }

  #TipoMad h1 {
    font-size: 2vw;
  }
}

/* Invio MAD */
@media screen and (max-width: 500px) {
  .Spunte {
    text-align: left;
  }

  input {
    width: 100%;
  }

  .Spunte input,
  .Container-DatiScuole input {
    width: fit-content;
  }

  #divOptions {
    margin: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: left;
    gap: 3%;
    padding: 5%;
  }

  .Options input {
    margin: 0;
    padding: 0;
  }

  .Options p {
    font-size: 12px;
    margin: 0;
    padding: 0;
    object-fit: fill;
  }

}

@media screen and (min-width: 500) {
  .Spunte {
    text-align: left;
  }

  input {
    width: 100%;
  }

  .Spunte input,
  .Container-DatiScuole input {
    width: fit-content;
  }
}

/* Riepilogo */
@media screen and (max-width: 500px) {
  #ContainerRiepilogo {
    font-size: max(2.5vw, 12px);
  }
}

@media screen and (min-width: 500px) {
  #ContainerRiepilogo {
    font-size: 1.5vw;
    margin: 5% 10%;
  }
}

@media screen and (min-width: 700px) {
  #ContainerRiepilogo {
    font-size: 1.2vw;
  }

  #ContainerRiepilogo h2 {
    padding: 5%;
  }
}

@media screen and (min-width: 900px) {
  #ContainerRiepilogo {
    font-size: 1.1vw;
  }

  #ContainerRiepilogo h2 {
    padding: 3%;
  }
}

/* Sovra Intestazione */
@media screen and (max-width: 500px) {

  #SovraIntestazione label,
  #SovraIntestazione div {
    font-size: max(2vw, 12px);
  }

  img {
    height: 2.2vw;
  }
}

@media screen and (min-width: 500px) {

  #SovraIntestazione label,
  #SovraIntestazione div {
    font-size: 1.6vw;
  }

  img {
    height: 1.8vw;
  }
}

@media screen and (min-width: 700px) {

  #SovraIntestazione label,
  #SovraIntestazione div {
    font-size: 1.3vw;
  }

  img {
    height: 1.6vw;
  }
}

@media screen and (min-width: 900px) {

  #SovraIntestazione label,
  #SovraIntestazione div {
    font-size: 1vw;
  }

  img {
    height: 1.2vw;
  }
}

/* Footer */
@media screen and (max-width: 600px) {
  #div-Footer h2 {
    font-size: 4vw;
  }

  #div-Footer .Link {
    font-size: max(2vw, 12px);
  }
}

@media screen and (min-width: 900px) {
  #div-Footer h2 {
    font-size: 3vw;
  }

  #div-Footer .Link {
    font-size: max(1vw, 12px);
  }

  #LogoAws {
    width: 10vw;
    height: 3vw;
  }
}






.spinner {
  animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation:
    dash 1.4s ease-in-out infinite,
    colors 5.6s ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: var(--color10);
  }

  25% {
    stroke: var(--color10);
  }

  50% {
    stroke: var(--color10);
  }

  75% {
    stroke: var(--color10);
  }

  100% {
    stroke: var(--color10);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }

  50% {
    stroke-dashoffset: 45;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}



.moveToLeft {
  -webkit-animation: moveToLeft 0.4s ease ease-out;
  animation: moveToLeft 0.4s ease-out;
}

@-webkit-keyframes moveToLeft {
  from {
    -webkit-transform: translateX(75%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes moveToLeft {
  from {
    -webkit-transform: translateX(75%);
    transform: translateX(75%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }
}




.moveToRight {
  -webkit-animation: moveToRight 0.7s ease ease-out;
  animation: moveToRight 0.7s ease-out;
}

@-webkit-keyframes moveToRight {
  from {
    -webkit-transform: translateX(-95%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes moveToRight {
  from {
    -webkit-transform: translateX(-95%);
    transform: translateX(-95%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    opacity: 1;
  }
}



.moveToTop {
  -webkit-animation: moveToTop 0.7s ease ease-out;
  animation: moveToTop 0.7s ease-out;
}

@-webkit-keyframes moveToTop {
  from {
    -webkit-transform: translateY(95%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes moveToTop {
  from {
    -webkit-transform: translateY(95%);
    transform: translateY(95%);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }
}




.moveToBottom {
  -webkit-animation: moveToBottom 0.7s ease ease-out;
  animation: moveToBottom 0.7s ease-out;

}

@-webkit-keyframes moveToBottom {
  from {
    -webkit-transform: translateY(-200px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes moveToBottom {
  from {
    -webkit-transform: translateY(-200px);
    transform: translateY(-200px);
    opacity: 0;
  }

  to {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }
}





.MioAd {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, hsl(34, 100%, 52%), red);
}

@media screen and (max-width: 700px) {
  .MioAd {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
  }


}